/* inconsolata-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inconsolata/inconsolata-v31-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/inconsolata/inconsolata-v31-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/inconsolata/inconsolata-v31-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/inconsolata/inconsolata-v31-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/inconsolata/inconsolata-v31-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/inconsolata/inconsolata-v31-latin-regular.svg#Inconsolata') format('svg'); /* Legacy iOS */
  }   
 
/* rubik-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Rubik-old';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/rubik/rubik-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/rubik/rubik-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/rubik/rubik-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/rubik/rubik-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/rubik/rubik-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/rubik/rubik-v23-latin-regular.svg#Rubik') format('svg'); /* Legacy iOS */
  }

  /* rubik - latin */
@font-face {
    font-family: 'Rubik';
    font-stretch: 1 99; /* Note that font-stretch is a % of normal width */
    font-style: normal; /* See note below */
    font-weight: 1 999;
    src: url('../fonts/rubik/Rubik-VariableFont_wght.ttf') format('truetype-variations'),
         url('../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  }

    /* rubik italic - latin */
@font-face {
    font-family: 'Rubik';
    font-stretch: 1 99; /* Note that font-stretch is a % of normal width */
    font-style: italic; /* See note below */
    font-weight: 1 999;
    src: url('../fonts/rubik/Rubik-Italic-VariableFont_wght.ttf') format('truetype-variations'),
         url('../fonts/rubik/Rubik-Italic.ttf') format('truetype');
  }

  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Robotomono';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/robotomono/robotomono-regular.ttf') format('truetype');
  }