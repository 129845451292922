@import "./fonts/font.css";

$body-bg: #f1f1f1;
$body-font-size: 1rem;

$nav-link-color: black;
$nav-link-font-weight: 400;
$navbar-padding-y: 0.05rem;
$navbar-padding-x: 0.3rem;
$navbar-brand-padding-y: 0.3rem;
$navbar-brand-margin-end: 0.2rem;
$dropdown-spacer: 0;

$blue: #00adee;
$accordion-color: #00adee;
$accordion-bg: white;
$accordion-border-width: 0px;
$accordion-border-radius: 3px;
$accordion-button-active-bg: #00adee;
$accordion-button-active-color: white;
$accordion-button-padding-x: 15px;
$accordion-button-padding-y: 10px;

$card-cap-bg: white;
$card-border-width: 0px;
$card-border-radius: 10px;
$card-spacer-y: 1.2rem;
$card-spacer-x: 1.1rem;

$font-family-sans-serif: "Rubik", sans-serif !default;
$font-family-monospace: "Robotmono", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$tooltip-bg: #cccccc;
$tooltip-color: black;

.modal-backdrop.show {
    z-index: 990;
}
.modal.show {
    z-index: 991;
}

html {
    font-size: 16px;
}

input[type] {
    border-radius: 10px;
    margin-bottom: 10px;
}

.accordion {
    margin-bottom: 20px;
}
.accordion-body {
    color: black;
}

.navbar-toggler {
    border: none !important;
}

.btn-primary {
    color: white !important;
}

.rdt_TableCell {
    font-family: $font-family-monospace;
    letter-spacing: -0.05rem;
    font-size: 14px;
}

.rdt_TableCol_Sortable div {
    font-family: $font-family-monospace;
    font-weight: 600;
}

footer {
    .navbar {
        background-color: #020202 !important;
    }

    .navbar-text {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        color: #ffffff;
        font-size: 0.9rem !important;
    }
}

.footer-link {
    text-decoration: none;
    color: #00adee !important;
}

.login-form {
    max-width: 400px;
    border-radius: 15px !important;
    text-align: center;
    margin: 50px auto !important;
    padding: 20px;

    .btn-primary {
        margin: 10px 0 10px 0;
    }

    img {
        padding-top: 5px;
    }
}

.enter-form {
    max-width: 400px;
    text-align: center;
    padding: 20px;

    .btn-primary {
        margin: 10px 0 10px 0;
    }

    img {
        padding-top: 5px;
    }
}

.welcome-text {
    color: $blue;
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.alert {
    padding: 5px 10px 5px 10px !important;
}

.navbar-text {
    width: 100%;
    text-align: center;
}

.navbar-nav a {
    color: #212529 !important;
}

.nav-link:hover {
    background: #b2e6fa;
}

.no-link-nav-item {
    padding: 0.5rem;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    background-color: white !important;
}

.modal-content {
    background-color: white !important;
}

main {
    min-height: 23rem;
    margin-bottom: 5rem;
}

.center {
    text-align: center;
}

.welcome-box {
    h1 {
        font-size: 2rem;
        margin-top: 15px;
        margin-bottom: 25px;
    }
    p {
        font-size: 1.1rem;
    }
}

.logo-img {
    height: 36px !important;
    width: 240px !important;
}

.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    padding: 0 8px;
    //border: 1px solid rgb(214, 216, 218);
    //border-radius: 6px;
    color: #212529;
    background-color: #f1f1f1;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus-within {
        border: 1px solid $blue;
    }
    input {
        flex: 1;
        border: none;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        padding: 14px 0 0 10px;
        background-color: #f1f1f1;
        color: #212529;
        &:focus {
            outline: transparent;
        }
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: $blue;
    .tag-title {
        margin-top: 3px;
    }
    .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 13px;
        text-align: center;
        font-size: 14px;
        margin-left: 8px;
        color: $blue;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
    }
}

.info-button {
    float: right;
    font-size: 1.1rem;
    margin-right: 7px;
}

.carret-icon {
    font-size: 1.2rem;
    margin-left: 8px;
}

.wrench-icon {
    font-size: 1.1rem;
    margin-left: 16px;
    margin-right: 10px;
}

.edit-icon {
    float: right;
    font-size: 1rem;
    margin-right: 7px;
    cursor: pointer;
    color: $blue;

    &:hover {
        color: #b2e6fa;
    }
}

.alert-dismissible .btn-close {
    padding: 0.7rem !important;
    font-size: 12px;
}

.card {
    box-shadow: 5px 5px 10px #e5e5e5 !important;
    margin: 20px;
    background-color: white !important;
}

.gadget-card {
    padding: 0px;
    background: white;
    box-shadow: 5px 5px 10px #e5e5e5;
    margin-top: 20px;

    .card-header {
        color: #00adee !important;
        padding: 5px;

        p {
            font-size: 18px;
            margin-bottom: 0;
        }

        &:hover {
            color: white !important;
            background: #00adee !important;
        }
    }

    .card-body {
        padding: 10px !important;
    }
}

.open {
    border-radius: 10px 10px 0 0 !important;
}

.close {
    border-radius: 10px 10px 10px 10px !important;
}

.index-info {
    h2 {
        font-size: 1.25rem;
    }
}

.login-head {
    color: #00adee;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
}

.login-text {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 1.25rem;
}

.password-container {
    position: relative;
}
.password-eye {
    position: absolute;
    top: 28%;
    right: 4%;
    cursor: pointer;
}

.svg-inline--fa {
    vertical-align: -0.2em;
}

.dropdown:hover > .dropdown-menu {
    display: block;
}

.dropdown-content {
    min-height: 400px;
}

.top-right-menu {
    position: absolute;
    top: 90px;
    right: 10px;

    td {
        padding: 0.1rem !important;
    }

    .btn {
        line-height: 1.5 !important;
        font-size: 14px !important;
        letter-spacing: -1px !important;
        width: 34px;
        height: 33px;
    }

    .btn:active {
        font-size: 13px !important;
    }
}

.position-graph-messages,
.bare-jpg-image-viewer-messages {
    color: red;
    text-align: center;
}

.row-actions button {
    margin-left: 5px;
}

.rmsc {
    --rmsc-bg: #f1f1f1;
    --rmsc-radius: 0.375rem;
    --rmsc-main: #80d6f7;
    --rmsc-gray: #212529;
    --rmsc-border: #ced4da;
}

.drag-and-drop-column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    width: 100%;
    min-height: 500px;
    gap: 8px;
}

.demo-title-bar {
    font-size: 1.25rem;
    font-weight: 500;
}

.rmsc .gray {
    color: #212529 !important;
}

.rmsc .dropdown-container {
    background-color: #f1f1f1 !important;
}

.stage-slider-control {
    font-family: $font-family-monospace;
}

.stage-slider-control .alert {
    font-family: $font-family-sans-serif;
}

.stage-control-messages {
    font-family: $font-family-sans-serif !important;
}

.nano-scale-head-logos {
    width: 100%;
}

.nano-scale-head-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: $blue;
}

.nano-scale-content {
    text-align: center;
}

.nano-scale-content h1 {
    font-size: 1.9rem;
    color: $blue;
    margin-top: 60px;
    margin-bottom: 20px;
}

.nano-scale-link {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #008abe;
    }
}

.welcome-page-button-list {
    text-align: center;

    span {
        margin-right: 10px;
        line-height: 45px;
    }
}

.redux-pic {
    width: 500px !important;
    text-align: center;
    margin: 0 auto;
}

.welcome-box-sirocco {
    width: 30% !important;
}

.welcome-box-second-pic {
    width: 100% !important;
    margin-bottom: 30px;
}

.maps-logo {
    width: 100% !important;
    margin-top: 10px;
    margin-bottom: 20px;
}

.react-player {
    width: 100% !important;
    height: 100% !important;
}

.ck-editor-save-button {
    margin-top: 1rem;
}

@media (min-width: 470px) {
    .enter-form {
        margin: 0 auto !important;
    }
}

@media (min-width: 1200px) {
    .stage-channel-control {
        scale: 0.95;
    }
}
@media (min-width: 1400px) {
    .stage-channel-control {
        scale: 0.9;
    }
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
